import React from 'react';
import './App.css';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import {
  BrowserRouter as Router, Link,
} from 'react-router-dom';
import {Container, Divider} from "@material-ui/core";

function App() : JSX.Element {
  return (
    <div className="App">
      <Router>
      <Container className="site-container" maxWidth="sm">

        <br/>
        <Typography variant="h1"><s>Gensler Ser<br/> please get Twitter!</s></Typography>
        <br/>
        <Typography variant="h4">Update: Woo he got it!<br/><a target="_blank" href="https://twitter.com/search?q=%23influencer">#influencer</a>&nbsp;<a target="_blank" href="https://twitter.com/GaryGensler">@GaryGensler</a></Typography>
        <br/>

        <p><a href="https://unegma.com" target="_blank" className="hi">By Unegma (not affiliated with the SEC)<br/></a></p>
        <br/>
      </Container>

      </Router>
    </div>
  );
}

export default App;
